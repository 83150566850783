<template>
  <div class="custom-bg">
    <ClientOnly>
      <WsPartnershipBanner />
    </ClientOnly>

    <WsHeaderDesktop
      :is-logged="isLogged"
      :user="session"
      :show-subscription="showSubscription"
      :notifications="feed"
      :user-public="userPublic"
      :show-black-header="uiStore.getShowBlackHeader"
    />
    <main>
      <CaSnackbar
        v-bind="snackbar"
        @close="closeSnackbar"
      />
      <slot />
    </main>
    <WsFooterDesktop :margin-top="false" />
  </div>
</template>

<script setup>
// Use auth store
const authStore = useAuthStore();
const { isLogged, getSession: session } = authStore;

// Use UI store
const uiStore = useUiStore();
const { showSubscription } = uiStore;

// Use notifications store
const notificationsStore = useNotificationsStore();
const { getFeed: feed, snackbar, setSnackbar } = notificationsStore;

// Use users store
const usersStore = useUsersStore();
const { getUserPublic: userPublic } = usersStore;

function closeSnackbar() {
  setSnackbar({
    type: '',
    show: false,
    message: '',
  });
}
</script>

<style lang="scss">
@import '@petlove/lion/styles/foundation-desktop.scss';

.custom-bg {
  background-color: color(neutral, bg);
}
</style>
